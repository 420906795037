import {
  POST_INSURANCE_OFFER_ALL,
  POST_INSURANCE_OFFER_ADD,
  POST_INSURANCE_OFFER_EDIT,
  POST_INSURANCE_OFFER_SINGLE,
  POST_INSURANCE_OFFER_ADD_FILE,
  POST_INSURANCE_OFFER_EDIT_FILE,
  POST_INSURANCE_OFFER_DELETE_FILE,
  POST_INSURANCE_OFFER_REORDER_FILES,
  POST_INSURANCE_OFFER_ADD_OFFER_PACK,
  POST_INSURANCE_OFFER_EDIT_OFFER_PACK,
  POST_INSURANCE_OFFER_DELETE_OFFER_PACK,
  POST_INSURANCE_OFFER_REORDER_OFFER_PACK,
  POST_INSURANCE_OFFER_DELETE
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postInsuranceOfferAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_ALL, payload: response.data});
};

    
export const postInsuranceOfferAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_ADD, payload: response.data});
};

export const postInsuranceOfferEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_EDIT, payload: response.data});
};

export const postInsuranceOfferDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }
  
  dispatch({type: POST_INSURANCE_OFFER_DELETE, payload: response.data});
};

export const postInsuranceOfferSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_SINGLE, payload: response.data});
};

export const postInsuranceOfferAddFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/add-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_ADD_FILE, payload: response.data});
};


export const postInsuranceOfferEditFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/edit-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_EDIT_FILE, payload: response.data});
};


export const postInsuranceOfferDeleteFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/delete-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_DELETE_FILE, payload: response.data});
};


export const postInsuranceOfferReorderFiles = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/reorder-files`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_REORDER_FILES, payload: response.data});
};

export const postInsuranceOfferAddPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/add-offer-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_ADD_OFFER_PACK, payload: response.data});
};


export const postInsuranceOfferEditPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/edit-offer-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_EDIT_OFFER_PACK, payload: response.data});
};


export const postInsuranceOfferDeleteOfferPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/delete-offer-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_DELETE_OFFER_PACK, payload: response.data});
};

export const postInsuranceOfferReorderOfferPacks = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-offer/reorder-offer-packs`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_OFFER_REORDER_OFFER_PACK, payload: response.data});
};


