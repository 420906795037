import {
  POST_INSURANCE_CONTRACT_ALL,
  POST_INSURANCE_CONTRACT_ADD,
  POST_INSURANCE_CONTRACT_EDIT,
  POST_INSURANCE_CONTRACT_SINGLE,
  POST_INSURANCE_CONTRACT_ADD_FILE,
  POST_INSURANCE_CONTRACT_EDIT_FILE,
  POST_INSURANCE_CONTRACT_DELETE_FILE,
  POST_INSURANCE_CONTRACT_REORDER_FILES,
  POST_INSURANCE_CONTRACT_DELETE,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postInsuranceContractAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_ALL, payload: response.data});
};

    
export const postInsuranceContractAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_ADD, payload: response.data});
};

export const postInsuranceContractEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_EDIT, payload: response.data});
};

export const postInsuranceContractSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_SINGLE, payload: response.data});
};

export const postInsuranceContractDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_DELETE, payload: response.data});
};

export const postInsuranceContractAddFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/add-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_ADD_FILE, payload: response.data});
};


export const postInsuranceContractEditFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/edit-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_EDIT_FILE, payload: response.data});
};


export const postInsuranceContractDeleteFile = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/delete-file`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_DELETE_FILE, payload: response.data});
};


export const postInsuranceContractReorderFiles = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-contract/reorder-files`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_CONTRACT_REORDER_FILES, payload: response.data});
};
