import {
  POST_INSURANCE_OFFER_ALL,
  POST_INSURANCE_OFFER_ADD,
  POST_INSURANCE_OFFER_EDIT,
  POST_INSURANCE_OFFER_SINGLE,
  POST_INSURANCE_OFFER_ADD_FILE,
  POST_INSURANCE_OFFER_EDIT_FILE,
  POST_INSURANCE_OFFER_DELETE_FILE,
  POST_INSURANCE_OFFER_REORDER_FILES,
  POST_INSURANCE_OFFER_ADD_OFFER_PACK,
  POST_INSURANCE_OFFER_EDIT_OFFER_PACK,
  POST_INSURANCE_OFFER_DELETE_OFFER_PACK,
  POST_INSURANCE_OFFER_DELETE
} from '../actionTypes';


const INIT_STATE = {
  insuranceOfferData: null,
  addInsuranceOfferData: null,
  editInsuranceOfferData: null,
  singleInsuranceOfferData: null,
  addInsuranceOfferFileData: null,
  editInsuranceOfferFileData: null,
  deleteFileInsuranceOfferData: null,
  reorderFilesInsuranceOfferData: null,
  addInsuranceOfferOfferPackData: null,
  editInsuranceOfferOfferPackData: null,
  deleteInsuranceOfferPackData: null,
  deleteInsuranceOfferData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_INSURANCE_OFFER_ALL:
               return {
                ...state,
                insuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_ADD:
            return {
              ...state,
              addInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_EDIT:
            return {
              ...state,
              editInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_SINGLE:
            return {
              ...state,
              singleInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_DELETE:
            return {
              ...state,
              deleteInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_ADD_FILE:
            return {
              ...state,
              addInsuranceOfferFileData: action.payload
          }
          case POST_INSURANCE_OFFER_EDIT_FILE:
            return {
              ...state,
              editInsuranceOfferFileData: action.payload
          }
          case POST_INSURANCE_OFFER_DELETE_FILE:
            return {
              ...state,
              deleteFileInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_REORDER_FILES:
            return {
              ...state,
              reorderFilesInsuranceOfferData: action.payload
          }
          case POST_INSURANCE_OFFER_ADD_OFFER_PACK:
            return {
              ...state,
              addInsuranceOfferOfferPackData: action.payload
          }
          case POST_INSURANCE_OFFER_EDIT_OFFER_PACK:
            return {
              ...state,
              editInsuranceOfferOfferPackData: action.payload
          }
          case POST_INSURANCE_OFFER_DELETE_OFFER_PACK:
            return {
              ...state,
              deleteInsuranceOfferPackData: action.payload
          }
          
        default:
              return state;
        }
};
      
export default reducer;