import './insurance_offer.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_INSURANCE_OFFER_ADD, POST_INSURANCE_OFFER_DELETE, POST_INSURANCE_OFFER_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postInsuranceOfferAdd, postInsuranceOfferAll, postInsuranceOfferDelete, postInsuranceOfferEdit, } from '../../redux/insuranceOffer/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate, getLocalDateWithoutTime } from '../../helpers/General';
import EditInsuranceOfferItem from './EditInsuranceOfferItem';
import EditInsuranceOfferFiles from './EditInsuranceOfferFiles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditInsuranceOfferOfferPacks from './EditInsuranceOfferOfferPacks';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditNotificationItem from '../notifications/EditNotificationItem';
import { postNotificationsAdd } from '../../redux/notifications/action';

import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Box, Checkbox, TableContainer, Typography, useMediaQuery } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postInsuranceCompaniesAll } from '../../redux/insuranceCompany/action';
import { postInsuranceTypesAll } from '../../redux/insuranceType/action';
import { postUsersAll } from '../../redux/users/action';
import { Delete } from '@mui/icons-material';
import SweetAlert from 'sweetalert2';
import { PRIMARY_COLOR } from '../../constants';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InsuranceOffer = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const insuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.insuranceOfferData);
  const addInsuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.addInsuranceOfferData);
  const editInsuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.editInsuranceOfferData);
  const deleteInsuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.deleteInsuranceOfferData);
  const usersData = useSelector(({users}) => users.usersData);
  const insuranceCompaniesData = useSelector(({insuranceCompanies}) => insuranceCompanies.insuranceCompaniesData);
  const insuranceTypesData = useSelector(({insuranceTypes}) => insuranceTypes.insuranceTypesData);


  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [offersArr,setOfferArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [addOfferPacksModal,setAddOfferPacksModal] = useState(false);

  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [bannerTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  const [toGetPushData, setToGetPushData] = useState(false);
  const [pushModal, setPushModal] = useState(false);

  const [fromTitle, setFromTitle] = useState("");
  const [fromDesc, setFromDesc] = useState("");
  const [fromUserId, setFromUserId] = useState("");
  const [fromItemId, setFromItemId] = useState("");

  
    
  const [usersArr,setUsersArr] = useState([]);
  const [usersFilter, setUsersFilter] = useState(null);
  const [searchUser,setSearchUser] = useState(null);
  const [userId, setUserId] = useState(null);

  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceTypeCategory, setInsuranceTypeCategory] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [companiesArr,setCompaniesArr] = useState([]);
  const [insuranceTypesArr,setInsuranceTypesArr] = useState([]);
  const [insuranceTypeCategoriesArr,setInsuranceTypeCategoriesArr] = useState([]);
  const [searchOfferKeyword, setSearchOfferKeyword] = useState("");

  const [offerType, setOfferType] = useState("");


  //HOOKS
  useEffect(() => { //ONLOAD
    getData();
    getUserData();
    getCompanyData();
    getInsuranceTypesData();
  }, []);

  const getUserData = () => {
    let postData = {
      hasPaginate: false,
      searchKeyword: searchUser,
    };

    dispatch(postUsersAll(postData)); 
  }

  useEffect(() => {
    if (usersData != null){
       setUsersArr(usersData.users);
    } 
  },[usersData]);

  useEffect(() => { 
    if (insuranceCompaniesData != null){
      setCompaniesArr(insuranceCompaniesData.insurance_companies);
    }
  }, [insuranceCompaniesData]);

  useEffect(() => { //GOT DATA
    if (insuranceTypesData != null){
      setInsuranceTypesArr(insuranceTypesData.insurance_types);
    }
  }, [insuranceTypesData]);



  const getCompanyData = () => {
    let postData = {
      fromApp: true
    };

    dispatch(postInsuranceCompaniesAll(postData)); 
  }

  const getInsuranceTypesData = () => {
    let postData = {
      fromApp: true
    };

    dispatch(postInsuranceTypesAll(postData)); 
  }


  useEffect(() => { //GOT DATA
    if (editItem != null &&
       editItem.insuranceType != null && insuranceTypesArr != null && insuranceTypesArr.length > 0 && insuranceTypesData != null && insuranceTypeCategoriesArr.length == 0){
      setInsuranceType(editItem.insuranceType.id);
      hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
    }
  }, [insuranceTypesArr]);

  const hangleInsuranceTypeChange = (value,category) => {
    let id = value;
    var newInsuranceCategoriesArr = [];
    insuranceTypesArr.forEach(insuranceType => {
      if (insuranceType.id == value){
        newInsuranceCategoriesArr = insuranceType.categories;
      }
    });
    setInsuranceType(id);
    setInsuranceTypeCategory(category);
    setInsuranceTypeCategoriesArr(newInsuranceCategoriesArr);
  }


  useEffect(() => {
    if (userId != null){
       getData();
    } 
  },[userId]);

  useEffect(() => {
    if (insuranceTypeCategory != null){
       getData();
    } 
  },[insuranceTypeCategory]);


  useEffect(() => {
    if (insuranceCompany != null){
       getData();
    } 
  },[insuranceCompany]);

  useEffect(() => {
    if (offerType != null){
       getData();
    } 
  },[offerType]);
  

  useEffect(() => { //GOT DATA
    if (insuranceOfferData != null){
      setOfferArr(insuranceOfferData.insurance_offers);
      setTotalData(insuranceOfferData.total); // Set total docs 
      setLoadedFirstTime(true);
    }
  }, [insuranceOfferData]);

  useEffect(() => { //ADD DATA
    if (addInsuranceOfferData != null){
      if (addInsuranceOfferData.status) {
        toast.dismiss();
        toast.success(addInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_INSURANCE_OFFER_ADD, payload: null});
    }
  }, [addInsuranceOfferData]);

  useEffect(() => { //EDIT DATA
    if (editInsuranceOfferData != null){
      if (editInsuranceOfferData.status) {
        toast.dismiss();
        toast.success(editInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_INSURANCE_OFFER_EDIT, payload: null});
    }
  }, [editInsuranceOfferData]);


  useEffect(() => { //DELETE DATA
    if (deleteInsuranceOfferData != null){
      if (deleteInsuranceOfferData.status) {
        toast.dismiss();
        toast.success(deleteInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(deleteInsuranceOfferData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_INSURANCE_OFFER_DELETE, payload: null});
    }
  }, [deleteInsuranceOfferData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchOfferKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchOfferKeyword]);
  
  useEffect(() => { 
    if (bannerTypeSelect != null && bannerTypeSelect && bannerTypeSelect != 0){
      reloadData();
    }
  }, [bannerTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  useEffect(() => {
    if (userId != null && userId != ""){
      getData();
    }
  }, [userId]);
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false,
      sort_by: sort,
      limit: rowsPerPage,  // Send rowsPerPage as limit
      page: page + 1,  // Adjust for 1-based pagination on the backend
      has_paginate: true
    };


    if (userId != null && userId != ""){
      postData.filter_user = userId;
    }

    if (insuranceTypeCategory != null && insuranceTypeCategory != ""){
      postData.filter_insurance_type_category = insuranceTypeCategory;
    }

    if (insuranceCompany != null && insuranceCompany != ""){
      postData.filter_insurance_company = insuranceCompany;
    }


    if (searchOfferKeyword != null && searchOfferKeyword != ""){
      postData.filter_search = searchOfferKeyword;
    }

    if (offerType != null && offerType != ""){
      postData.filter_offer_type = offerType;
    }

    dispatch(postInsuranceOfferAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_banner_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogFilesClose = () => {
    setAddFilesModal(false);
  }

  const onDialogOfferPacksClose = () => {
    setAddOfferPacksModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postInsuranceOfferEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setEditItem(null);
      dispatch(postInsuranceOfferAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    }
  }

  const onErrorData = () => {
    setToGetData(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData();  // Fetch the new page data
};


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);  // Reset to first page after rows per page change
    getData();  // Fetch the updated data
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_banner_title)
    setEditItem(item);
    setAddModal(true);
  }


  const onViewFiles = (e,item) => {
    e.preventDefault();
    setEditItem(item);
    setAddFilesModal(true);
  }

  const onViewOfferPacks = (e,item) => {
    e.preventDefault();
    setEditItem(item);
    setAddOfferPacksModal(true);
  }


  //UI
  const columnData = [
    {id: "title", align: true, disablePadding: false, label:  trans.table_head_title},
    {id: "user", align: true, disablePadding: false, label: trans.table_head_user},
    {id: "contractNum", align: true, disablePadding: false, label: trans.table_head_insurance_contract_num},
    {id: "insuranceTypeCategory.title", align: true, disablePadding: false, label: trans.table_head_insurance_type},
    {id: "expireDate", align: true, disablePadding: false, label: trans.insurance_offer_expire_date},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at},
    {id: "status", align: true, disablePadding: false, label: trans.insurance_offer_state},
    
  ];

  const onChangeUserType = (value) => {
    setSearchTypeSelect(value);
  }
  
  const onChangeAccountStatus = (value) => {
    setAccountStatusSelect(value);
  }

  const onFilesUpdated = () => {
    reloadData();
  }

  const onOfferPacksUpdated = () => {
    reloadData();
  }


  const openToInform = (e,item) => {
    e.preventDefault();
    setFromTitle(`${trans.insurance_push_new_offer_title}`);
    setFromDesc(`${trans.insurance_push_new_offer_desc} ${item.insuranceTypeCategory.title}`);
    if (item.userId != null){
      setFromUserId(item.userId.id);
    }
    setFromItemId(item.id);
    setPushModal(true)
  }

  const onPushModalClose = () => {
    setPushModal(false);
  }

  const onDialogPushSubmit = () => {
    setToGetPushData(true);
  }

  const onSubmitPushData = (data) => {
    let postData = data; 
    dispatch(postNotificationsAdd(postData));
    setPushModal(false);
    setToGetPushData(false);
  }


  const searchOfferChanged = (value) => {
    setSearchOfferKeyword(value);
  }


  const handleUsersChange = (e, selectedValue) => {
    setUsersFilter(selectedValue);
    setUserId(selectedValue != null ? selectedValue.id : "");
    setSearchUser("");  // Clear search keyword
// Fetch filtered data
};

  const handleUserSearchInput = (value) => {
    setSearchUser(value);
  }


  useEffect(() => {
    if (searchUser != null){
      getUserData();
    } 
  },[searchUser]);


  const isSmallScreen = useMediaQuery('(max-width:1200px)'); // Detect small screens

  const isMediumScreen = useMediaQuery('(max-width:1600px)'); // Detect small screens



  const openToAskDelete = (e,item) => {
    e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            dispatch(postInsuranceOfferDelete({itemId: item.id}));
        }
      })
  }


  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_insurance_offers}</CustomTitle>
          <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button>
        </div>
        <br/>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">

          <Grid item  s={12} xs={12}  md={6}>
                <TextField
                  id="standard-search"
                  label={trans.search_field}
                  type="search"
                  fullWidth
                  variant="standard" 
                  onChange={(e) => setSearchOfferKeyword(e.target.value)}
                />
            </Grid>   
          
              
            <Grid item  s={12} xs={12}  md={6}>
              <Autocomplete
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                   
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullname}
                    onInputChange={(event, newInputValue) => {
                      handleUserSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullname}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
              </Grid>

              <Grid item xs={4}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_type">{trans.table_head_insurance_type}</InputLabel>
                  <Select
                          labelId="table_head_insurance_type"
                          id="table_head_insurance_type"
                          value={insuranceType}
                          label={trans.table_head_insurance_type}
                          onChange={(e) => hangleInsuranceTypeChange(e.target.value,null)}>
                           {insuranceTypesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={4}>
            <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_category">{trans.table_head_insurance_category}</InputLabel>
                  <Select
                          labelId="table_head_insurance_category"
                          id="table_head_insurance_category"
                          value={insuranceTypeCategory}
                          label={trans.table_head_insurance_category}
                          onChange={(e) => setInsuranceTypeCategory(e.target.value)}>
                           {insuranceTypeCategoriesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={4}>
        <FormControl fullWidth>
                  <InputLabel id="insurance_offer_type">{trans.insurance_offer_type}</InputLabel>
                  <Select
                          labelId="insurance_offer_type"
                          id="insurance_offer_type"
                          value={offerType}
                          label={trans.insurance_offer_type}
                          onChange={(e) => setOfferType(e.target.value)}>
                          <MenuItem key={`insurance_offer_type_user_offer`} value={"USER_OFFER"}>{trans.insurance_offer_type_user_offer}</MenuItem>
                          <MenuItem key={`insurance_offer_type_all_offer`} value={"ALL_OFFER"}>{trans.insurance_offer_type_all_offer}</MenuItem>
                  </Select>
              </FormControl>
        </Grid>

        </Grid>
      
        <div>
      <br />
      {!isSmallScreen ? (
        <TableContainer style={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columnData.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    style={{ padding: '4px 8px', whiteSpace: 'nowrap' }}
                  >
                    <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={() => createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {offersArr.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {row.offerType === 'USER_OFFER' ? row.userId.fullname : trans.insurance_offer_type_all_offer}
                  </TableCell>
                  <TableCell>{row.currentContract != null ? row.currentContract.contractNum : 'N/A'}</TableCell>
                  <TableCell>{row.insuranceTypeCategory.title}</TableCell>
                  <TableCell>{row.expires == false ? "N/A":getLocalDateWithoutTime(row.expireDate)}</TableCell>
                  <TableCell>{getLocalDateWithoutTime(row.created_at)}</TableCell>
                  <TableCell>{(new Date(row.expireDate) > new Date() && row.status == true || row.expires == false && row.status == true) ? <span style={{color: 'green'}}>{trans.table_head_active_contract}</span> : <span style={{color: 'red'}}>{trans.table_head_inactive_contract}</span>}</TableCell>
                  <TableCell>
                    {row.status ? trans.insurance_offer_state_active : trans.insurance_offer_state_inactive}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: isMediumScreen ? 'column' : 'row',
                        gap: '8px',
                      }}
                    >
                      <Button
                        startIcon={<HowToRegIcon />}
                        size="small"
                        color="secondary"
                        onClick={(e) => onViewRow(e, row)}
                        variant="outlined"
                      >
                        {trans.view_item_title}
                      </Button>
                      <Button
                        startIcon={<InventoryIcon />}
                        size="small"
                        color="secondary"
                        onClick={(e) => onViewOfferPacks(e, row)}
                        variant="outlined"
                      >
                        {trans.insurance_offer_offer_packs_btn}
                      </Button>
                      <Button
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                        color="secondary"
                        onClick={(e) => onViewFiles(e, row)}
                        variant="outlined"
                      >
                        {trans.insurance_offer_files}
                      </Button>
                      <Button
                        startIcon={<NotificationsActiveIcon />}
                        size="small"
                        color="secondary"
                        onClick={(e) => openToInform(e, row)}
                        variant="outlined"
                      >
                        {trans.table_head_inform_button}
                      </Button>
                      <Button
                      startIcon={<Delete />}
                      size="small"
                      color="secondary"
                      onClick={(e) => openToAskDelete(e, row)}
                      variant="outlined"
                    >
                      {trans.table_delete_button}
                    </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
        {offersArr.map((row) => (
          <Box
            key={row.id}
            style={{
              border: '1px solid #ddd',
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: '#2c2c2c',
            }}
          >
            {/* Spacing added between rows with mb={1} */}
            <Box mb={1}>
              <Typography variant="h6">
                <strong>{row.title}</strong>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.table_head_user}:</strong>{' '}
                {row.offerType === 'USER_OFFER' ? row.userId.fullname : trans.insurance_offer_type_all_offer}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.table_head_insurance_contract_num}:</strong>{' '}
                {row.currentContract != null ? row.currentContract.contractNum : 'N/A'}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.table_head_insurance_type}:</strong> {row.insuranceTypeCategory.title}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.insurance_offer_expire_date}:</strong> {getLocalDateWithoutTime(row.expireDate)}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.table_head_created_at}:</strong> {getLocalDateWithoutTime(row.created_at)}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body1">
                <strong>{trans.insurance_offer_state}:</strong>{' '}
                {row.status ? trans.insurance_offer_state_active : trans.insurance_offer_state_inactive}
              </Typography>
            </Box>
      
            {/* Add spacing between buttons */}
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
  <Box display="flex" gap={2}>
    <Button
      startIcon={<HowToRegIcon />}
      size="small"
      color="secondary"
      variant="outlined"
      onClick={(e) => onViewRow(e, row)}
    >
      {trans.view_item_title}
    </Button>
    <Button
      startIcon={<InventoryIcon />}
      size="small"
      color="secondary"
      variant="outlined"
      onClick={(e) => onViewOfferPacks(e, row)}
    >
      {trans.insurance_offer_offer_packs_btn}
    </Button>
  </Box>
  <Box display="flex" gap={2}>
    <Button
      startIcon={<PictureAsPdfIcon />}
      size="small"
      color="secondary"
      variant="outlined"
      onClick={(e) => onViewFiles(e, row)}
    >
      {trans.insurance_offer_files}
    </Button>
    <Button
      startIcon={<NotificationsActiveIcon />}
      size="small"
      color="secondary"
      variant="outlined"
      onClick={(e) => openToInform(e, row)}
    >
      {trans.table_head_inform_button}
    </Button>
  </Box>
</Box>

          </Box>
        ))}
      </Box>
      
      )}

    <TablePagination
        component="div"
        count={totalData} // Total number of records from backend
        rowsPerPage={rowsPerPage} // Rows per page state
        page={page} // Current page state
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.insurance_offer_add : trans.insurance_offer_edit }`}
        body={<EditInsuranceOfferItem toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} onErrorData={onErrorData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />         

       <FormDialog
        open={addFilesModal}
        onClose={onDialogFilesClose}
        title={`${editItem == null ? trans.insurance_offer_files : trans.insurance_offer_files }`}
        body={<EditInsuranceOfferFiles editItem={editItem} onFilesUpdated={onFilesUpdated}/> }
        maxWidth={"md"}
        hasSubmit={false}
      />       


      <FormDialog
        open={addOfferPacksModal}
        onClose={onDialogOfferPacksClose}
        title={`${editItem == null ? trans.insurance_offer_offer_packs_title : trans.insurance_offer_offer_packs_title }`}
        body={<EditInsuranceOfferOfferPacks editItem={editItem} onOfferPacksUpdated={onOfferPacksUpdated}/> }
        maxWidth={"md"}
        hasSubmit={false}
      />      

    <FormDialog
        open={pushModal}
        onClose={onPushModalClose}
        title={trans.add_notitification_title}
        body={<EditNotificationItem toGetData={toGetPushData} toClearData={() => {}} onSubmitData={onSubmitPushData} editItem={null} from="offers" fromTitle={fromTitle} fromDesc={fromDesc} fromUserId={fromUserId} fromItemId={fromItemId}/> }
        onSubmit={onDialogPushSubmit}
        maxWidth={"md"}
      />    

</Container>);
}

export default InsuranceOffer;